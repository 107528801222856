import 'portal/Bachelor/static/index.scss';

import EnterForm from '../components/EnterForm';
import Layout from '../components/Layout';
import Login from 'portal/Bachelor/components/Login';
import React from 'react';
import withConfig from 'global/components/HOC/withConfig';

const Home = props => {
	return (
		<>
			<Layout>
				<div className="bachelor--main-form">
					<Login />
					<div className="bachelor--main-form-inner">
						<p className="text-center">
							<strong>The Bachelor SA Season 2 is here! </strong>
							<br />
							Each week, LottoStar gives you a chance to win cash prizes with The Bachelor SA. <br />
							By voting in the BacheLEAGUE, you stand a chance to win up to R20,000 in cash, every week! <br />
							All you have to do is answer an easy question on the upcoming episode of the Bachelor SA. How easy is
							that? Enter now!
						</p>
						<hr />
						<EnterForm />
					</div>
				</div>
			</Layout>
		</>
	);
};

export default withConfig(Home, {
	slug: 'bachelor'
});
