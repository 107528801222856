export default {
	data: {
		errors: []
	},
	actions: {
		addError: ({ data }, errors) => {
			let errorList = [...data.errors];
			errors.map(error => {
				errorList.push(error.message);
				return true;
			});
			data.errors = errorList;
		},
		removeErrors: ({ data }) => {
			data.errors = [];
		}
	}
};
