import { Redirect, Route } from 'react-router-dom';

import React from 'react';
import store from 'store';

const AuthRoute = props => {
	const { errors, removeErrors } = props.pulse;
	React.useEffect(() => {
		if (errors.length > 0) {
			removeErrors();
		}
	}, [errors, removeErrors]);

	if (!props.auth) {
		return <Redirect to={props.redirect} />;
	}
	return <Route {...props} />;
};

export default store.wrapped(AuthRoute, {
	errors: 'error/errors',
	removeErrors: 'error/removeErrors'
});
