import './QuestionModal.scss';

import React, { useEffect, useState } from 'react';

import BlockContent from '@sanity/block-content-to-react';
import Button from 'portal/Bachelor/components/Button';
import Loading from 'portal/Bachelor/static/loading-big.gif';
import Reveal from 'react-reveal/Reveal';
import { queryCMS } from 'global/queries';
import urlFor from 'global/functions/urlFor';
import { withRouter } from 'react-router-dom';

const QuestionModal = props => {
	const { history, questionID } = props;
	let [stats, setStats] = useState(null);
	let [loading, setLoading] = useState(true);

	useEffect(() => {
		queryCMS(
			`*[_type == 'portalQuestion' && _id == $id && !(_id in path('drafts.**'))] { successTitle, successCopy, featured, totalEntries, "entries": entries[] {..., "contestant": contestant->} } | order(entries desc) [0]`,
			{
				id: questionID
			}
		)
			.then(res => {
				setStats(res);
				setLoading(false);
			})
			.catch(() => alert('Something went wrong, please try again'));
	}, [questionID]);

	return (
		<Reveal effect="fadeIn" duration={600}>
			<div className="bachelor-question-modal">
				<div className="bachelor-question-modal-inner">
					<div className="bachelor-question-modal-loading">
						<img src={Loading} alt="" />
					</div>

					<Reveal effect="fadeInUp" duration={600} when={!loading}>
						<div className="bachelor-question-modal-content">
							{!loading ? (
								<>
									<h1 className="text-uppercase mb30">{stats.successTitle}</h1>
									{stats.successCopy && <BlockContent blocks={stats.successCopy} />}
									<div className="bachelor-question-modal-content-stats">
										{stats.entries
											.sort((a, b) => {
												return b.value - a.value;
											})
											.slice(0, 5)
											.reverse()
											.map((entry, index) => {
												if (index <= 4) {
													let percentage = (entry.value / stats.totalEntries) * 100;
													percentage = percentage.toFixed(1).replace('.0', '');
													const percentageImg = Math.round((entry.value / stats.totalEntries) * 10) * 10;
													return (
														<div className="bachelor-question-modal-content-stats-stat" key={index}>
															<div className="bachelor-question-modal-content-stats-stat-value">
																<img
																	src={require(`portal/Bachelor/static/stats/${percentageImg}%.png`)}
																	alt={`${percentage}%`}
																/>
																<span>{percentage}%</span>
															</div>
															<div className="bachelor-question-modal-content-stats-stat-contestant">
																<img src={urlFor(entry.contestant.avatar)} alt={entry.contestant.name} />
																<h2 className="mt10">{entry.contestant.name}</h2>
															</div>
														</div>
													);
												} else {
													return null;
												}
											})}{' '}
									</div>
									<div className="bachelor-question-modal-inner-buttons">
										{/* eslint-disable-next-line */}
										<a target="_blank" href="https://lottostar.co.za/?aid=bacheleague_home">
											<Button text="TAKE ME TO LOTTOSTAR" />
										</a>
										<Button
											onClick={() => history.push('/bachelor/questions')}
											text={stats.featured ? 'TAKE THE POLL' : 'BACK TO POLL'}
										/>
									</div>{' '}
								</>
							) : (
								''
							)}
						</div>
					</Reveal>
				</div>
			</div>
		</Reveal>
	);
};

export default withRouter(QuestionModal);
