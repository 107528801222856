import './NoMatch.scss';

import Button from 'portal/Bachelor/components/Button';
import Four0Four from 'portal/Bachelor/static/404.png';
import Layout from 'portal/Bachelor/components/Layout';
import React from 'react';

const NoMatch = () => {
	return (
		<Layout blank>
			<div className="bachelor-404">
				<img src={Four0Four} alt="404" />
				<br />
				<br />
				<p style={{ maxWidth: '500px', margin: '0 auto' }}>
					Sorry! We couldn’t find you a rose but you still have an opportunity to place your vote and stand a chance to
					win R20 000.
				</p>
				<a href="/bachelor">
					<Button text="Vote Now" />
				</a>
			</div>
		</Layout>
	);
};

export default NoMatch;
