import * as Yup from 'yup';

import { Col, Row } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import Button from 'portal/Bachelor/components/Button';
import Terms from 'portal/Bachelor/components/Terms';
import { queryApiClient } from 'global/queries';
import store from 'store';
import { withRouter } from 'react-router-dom';

const EnterForm = ({ history }) => {
	let [submitting, setSubmitting] = useState(false);
	let [modal, setModal] = useState(false);
	let [error, setError] = useState(null);

	const handleSubmit = values => {
		setSubmitting(true);
		setError(null);
		const data = {
			portal_slug: 'the-bachelor',
			first_name: values.first_name,
			last_name: values.last_name,
			number_prefix: '27',
			mobile_number: values.contact_number.replace(/ /g, ''),
			email: values.email_address,
			entry_pin: values.pin,
			extra: { marketing_consent: [] },
			id_number: values.identity_number
		};
		if (values.mnet) data.extra.marketing_consent.push('mnet');
		if (values.toyota) data.extra.marketing_consent.push('toyota');
		queryApiClient({ url: '/portal/competitionentry', data })
			.then(response => {
				store.auth.setLoginTokenExpiry(response._metadata.token.expire_at);
				store.auth.setLoginToken(response.data.login_token);
				store.auth.setEntryID(response.data.entry_id);
			})
			.catch(err => {
				setError(err[0].message);
				setSubmitting(false);
			});
	};

	const EnterSchema = Yup.object().shape({
		first_name: Yup.string().required('This field is required'),
		last_name: Yup.string().required('This field is required'),
		identity_number: Yup.string()
			.required('This field is required')
			.matches(/^\d{13}$/, {
				message: 'Please enter a valid 13 digit Identity Number',
				excludeEmptyString: false
			}),
		contact_number: Yup.string()
			.required('This field is required')
			.matches(/^(0)\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, {
				message: 'Please enter valid a contact number. E.g. 079\xa0XXX\xa0XXXX',
				excludeEmptyString: true
			}),
		email_address: Yup.string()
			.email('Please enter a valid email address')
			.required('This field is required'),
		pin: Yup.string()
			.required('This field is required')
			.matches(new RegExp('^[0-9]+$'), 'PIN can only contain numbers')
			.test('len', 'Please enter a 4 digit PIN', val => val && val.toString().length === 4),
		terms: Yup.boolean().oneOf([true], 'Please make sure you have agreed to our terms and conditions.')
	});

	return (
		<>
			{modal && <Terms setActive={setModal} />}
			<Formik
				validationSchema={EnterSchema}
				initialValues={{
					first_name: '',
					last_name: '',
					identity_number: '',
					contact_number: '',
					email_address: '',
					pin: '',
					mnet: false,
					toyota: false,
					terms: false
				}}
				onSubmit={values => handleSubmit(values)}
			>
				{({ errors, touched }) => (
					<Form>
						<Row>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="first_name" type="text" placeholder="First Name" />
									{errors.first_name && touched.first_name && (
										<div className="input-wrapper-error">{errors.first_name}</div>
									)}
								</div>
							</Col>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="last_name" type="text" placeholder="Last Name" />
									{errors.last_name && touched.last_name && (
										<div className="input-wrapper-error">{errors.last_name}</div>
									)}
								</div>
							</Col>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="identity_number" type="text" placeholder="Identity Number" />
									{errors.identity_number && touched.identity_number && (
										<div className="input-wrapper-error">{errors.identity_number}</div>
									)}
								</div>
							</Col>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="contact_number" type="tel" placeholder="Contact Number" />
									{errors.contact_number && touched.contact_number && (
										<div className="input-wrapper-error">{errors.contact_number}</div>
									)}
								</div>
							</Col>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="email_address" type="text" placeholder="Email Address" />
									{errors.email_address && touched.email_address && (
										<div className="input-wrapper-error">{errors.email_address}</div>
									)}
								</div>
							</Col>
							<Col lg={4} md={6}>
								<div className="input-wrapper">
									<Field name="pin" type="password" placeholder="Create PIN" />
									{errors.pin && touched.pin && <div className="input-wrapper-error">{errors.pin}</div>}
								</div>
							</Col>
							<Col lg={12} md={6}>
								<div className="input-wrapper-checkbox text-center" style={{ marginTop: '-10px' }}>
									<Field id="terms" name="terms" type="checkbox" />
									<label htmlFor="terms">
										Accept{' '}
										<div className="terms" onClick={() => setModal(true)}>
											T’s &amp; C’s
										</div>
									</label>
									{errors.terms && touched.terms && <div className="input-wrapper-error">{errors.terms}</div>}
								</div>
							</Col>
							<Col xs={12} className="text-center mb30">
								<div className="input-wrapper-text">I would like to receive marketing material from</div>
								<div className="input-wrapper-checkbox inline">
									<Field id="mnet" name="mnet" type="checkbox" />
									<label htmlFor="mnet">M-Net</label>
								</div>
								<div className="input-wrapper-checkbox inline">
									<Field id="toyota" name="toyota" type="checkbox" />
									<label htmlFor="toyota">Toyota</label>
								</div>
							</Col>
						</Row>
						{error && <div className="error mb20">{error}</div>}
						<Button loading={submitting} centered type="submit" text="SUBMIT &amp; VOTE NOW" />
					</Form>
				)}
			</Formik>
		</>
	);
};

export default withRouter(EnterForm);
