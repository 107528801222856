import './error.scss';

import React, { useEffect, useState } from 'react';

import ErrorImage from 'portal/Bachelor/static/error.png';
import store from 'store';
import { withRouter } from 'react-router-dom';

const Error = props => {
	const { history } = props;
	const { errors, removeErrors, loginOpen } = props.pulse;
	let [active, setActive] = useState(false);
	let [percentage, setPercentage] = useState(100);

	useEffect(() => {
		let timeout;
		let secondaryTimeout;
		if (errors.length > 0) {
			setActive(true);
			timeout = setTimeout(() => {
				setActive(false);
				secondaryTimeout = setTimeout(() => {
					removeErrors();
					setPercentage(100);
				}, 500);
			}, 5000);
		}
		return () => {
			clearTimeout(timeout);
			clearInterval(secondaryTimeout);
			setPercentage(100);
		};
	}, [errors, setPercentage, removeErrors]);

	useEffect(() => {
		let interval;
		if (errors.length > 0) {
			interval = setInterval(() => {
				setPercentage(p => (p >= 0 ? p - 2 : p));
			}, 100);
		}
		return () => {
			clearInterval(interval);
		};
	}, [errors, setPercentage]);

	return (
		<div
			onClick={() => {
				setActive(false);
				setTimeout(() => {
					removeErrors();
					setPercentage(100);
				}, 500);
			}}
			className={`bachelor--error ${loginOpen && history.location.pathname === '/bachelor' ? 'loginOpen' : ''} ${
				active ? 'active' : ''
			}`}
		>
			<img src={ErrorImage} alt="" />
			<div style={{ width: `${percentage}%` }} className="bachelor--error-timer"></div>
			{errors.map((error, index) => (
				<div className="bachelor--error-error" key={index}>
					- {error}
				</div>
			))}
		</div>
	);
};
export default store.wrapped(withRouter(Error), {
	errors: 'error/errors',
	removeErrors: 'error/removeErrors',
	loginOpen: 'bachelor/loginOpen'
});
