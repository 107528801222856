export default {
	data: {
		entryID: null,
		clientToken: false,
		loginToken: false,
		loginTokenExpiry: null,
		codeBlacklist: [
			1056,
			1063,
			1064,
			1081,
			1086,
			1090,
			1120,
			3070,
			3071,
			3072,
			3093,
			3120,
			3062,
			4521,
			5020,
			5026,
			4015,
			4006
		]
	},
	actions: {
		setEntryID: ({ data }, id) => {
			data.entryID = id;
		},
		setClientToken: ({ data }, token) => {
			data.clientToken = token;
		},
		setLoginTokenExpiry: ({ data }, expiry) => {
			data.loginTokenExpiry = expiry;
		},
		setLoginToken: ({ data }, token) => {
			data.loginToken = token;
		}
	},
	watch: {
		loginToken: ({ data }) => {
			if (data.loginToken === false) {
				data.loginTokenExpiry = null;
			}
		}
	},
	persist: ['entryID', 'clientToken', 'loginToken', 'loginTokenExpiry']
};
