import './terms.scss';

import { FaTimes } from 'react-icons/fa';
import React from 'react';
import Reveal from 'react-reveal/Reveal';

const Terms = props => {
	const { setActive } = props;

	const handleClose = () => {
		setActive(null);
	};

	return (
		<Reveal effect="fadeIn" duration={400}>
			<div className="terms-modal" onClick={handleClose}>
				<div className="terms-modal-inner" onClick={e => e.stopPropagation()}>
					<div className="terms-modal-close" onClick={handleClose}>
						<FaTimes />
					</div>
					<h1 className="mb40">Terms & Conditions</h1>
					<div className="terms-modal-inner-content">
						<strong>Lottostar Terms and Conditions</strong>
						<ol>
							<li>
								General
								<ol>
									<li>
										In addition to the standard LottoStar Terms and Conditions (“Ts&Cs”), which can be found at{' '}
										<a href="https://lottostar.co.za/terms-and-conditions" target="_blank" rel="noopener noreferrer">
											https://lottostar.co.za/terms-and-conditions
										</a>{' '}
										and the Promotional Ts&Cs, available at{' '}
										<a
											href="https://lottostar.co.za/promotional-terms-and-conditions/"
											target="_blank"
											rel="noopener noreferrer"
										>
											https://lottostar.co.za/promotional-terms-and-conditions/
										</a>
										, the below Ts&Cs also apply to LottoStar’s involvement in The Bachelor South Africa Season 2, The
										BacheLEAGUE and The Bachelor ScratchCard Game.
									</li>
									<li>Entries are only open to participants 18 years of age or older.</li>
									<li>Participants are required to be in possession of a valid South African Identity Document.</li>
									<li>
										LottoStar will not accept competition entries that are:
										<ol>
											<li>automatically generated by computer;</li>
											<li>completed by third parties or in bulk;</li>
											<li>have been altered, reconstructed, forged or tampered with; or</li>
											<li>incomplete.</li>
										</ol>
									</li>
									<li>
										The BacheLEAGUE and The Bachelor ScratchCard Game are not open to employees, agencies, prize
										sponsors or contractors of LottoStar, DSTV, M-Net or any person directly or indirectly involved in
										the organisation or running of the competition, or their immediate family members.
									</li>
									<li>The judges' decision is final and no correspondence will be entered into.</li>
									<li>
										LottoStar does not accept any responsibility if participants are not able to take up the prize.
									</li>
									<li>
										Winners agree that LottoStar may use their name, image and town of residence to announce any winner
										of this competition and for any other reasonable and related promotional purposes. Furthermore, any
										winner may be required to have their details disclosed and appear on radio, television, the Internet
										or in printed publications.
									</li>
									<li>Winners further agree to participate in any reasonable publicity required by LottoStar.</li>
									<li>
										In terms of the provisions of the Financial Intelligence Center Act of 2001 (“FICA”) it will be
										necessary for the winners’ to furnish LottoStar with a copy their identity details (from an identity
										document/passport which must be valid), proof of residence in the nature of a statement/invoice of
										account (rendered by a City Council or a bank in the case of mortgaged property) and proof of bank
										account details (the aforementioned statements/proof shall not be older than 3 (three) months and
										must be in the winner’s name). All documents must be furnished within 3 working days of the request
										for the documents. Should any winner have failed to comply with the provisions of FICA then
										LottoStar shall be prohibited from paying the prize money to the winner.
									</li>
									<li>Prizes are not transferrable and non-negotiable.</li>
									<li>
										All participants and winners, as the case may be, indemnify LottoStar and/or associated companies
										against any and all claims of any nature whatsoever arising out of and/or from their participation
										in any way howsoever in this competition (including, as a result of any act or omission, whether as
										a result of negligence, misrepresentation, misconduct or otherwise on the part of the Promoter)
										and/or use of the prize.
									</li>
									<li>
										Any violation or attempt to violate any of the above rules will result in the immediate
										disqualification of the transgressor.
									</li>
									<li>
										LottoStar may disqualify a transgressor for entry into any other LottoStar competitions, for a
										period deemed appropriate by LottoStar Management.
									</li>
									<li>
										LottoStar reserves the right to cancel the competition at any time, if deemed necessary in their
										opinion, and if circumstances arise outside of their control and shall not be liable in anyway
										whatsoever to participants in such event for any cause or action whatsoever.
									</li>
									<li>
										Winners are only finalised once they have been announced on the M-Net social media pages, LottoStar
										social media pages and the website{' '}
										<a href="https://m-net.dstv.com/show/the-bachelor-sa_828" target="_blank" rel="noopener noreferrer">
											https://m-net.dstv.com/show/the-bachelor-sa_828
										</a>
										. LottoStar will also contact all cash prize winners.
									</li>
									<li>
										In the event that any winner is disqualified from the competition, LottoStar will, in its
										discretion, select an alternative prize winner in the same manner as the original prize winner and
										such selection will be subject to these terms and conditions.
									</li>
									<li>
										By registering for the BacheLEAGUE, you are agreeing to be bound by these terms and conditions.
									</li>
									<li>
										By registering for the BacheLEAGUE, you hereby warrant that all information submitted by you in your
										entry is true, accurate and complete in every respect. LottoStar reserves the right to verify any
										information contained in your entry and/or your eligibility to enter the competition.
									</li>
								</ol>
							</li>
							<li>
								The BacheLEAGUE
								<ol>
									<li>
										The BacheLEAGUE is a voting competition that will be run by LottoStar during the Bachelor South
										Africa (Season 2) broadcast by DSTV on M-Net. Participants will be prompted to the following URL:
										<a href="https://m-net.dstv.com/show/the-bachelor-sa_828" target="_blank" rel="noopener noreferrer">
											https://m-net.dstv.com/show/the-bachelor-sa_828
										</a>{' '}
										where they must register by entering the following details: first and last name; identity number;
										email address; and a 4-digit PIN. The participants must accept these Terms and Conditions and click
										“SUBMIT AND VOTE NOW”.
									</li>
									<li>
										Once a participant has successfully registered, they will thereafter only need to submit their
										mobile number and 4-digit PIN to login.
									</li>
									<li>
										To stand a chance to win, registered participants need to answer the main question, which question
										will be updated on a weekly basis. Participants can only answer the main question once a week during
										The BacheLEAGUE voting competition.
									</li>
									<li>
										In the event that more than one girl is sent home in any given week, only one correct answer is
										required.
									</li>
									<li>
										Once a participant has submitted their answer they will be asked whether they would like to go to
										www.lottostar.co.za or whether they would like to participate in the poll. Participation in the poll
										is for entertainment purposes only and no prizes are awarded for doing so.
									</li>
									<li>
										From the group of participants who have answered the main question correctly, one winner will be
										randomly selected and will win a cash prize of R5,000 (five thousand Rand). In in the event that the
										winner is an active LottoStar Player as defined in clause 2.7 below, then they will win R20,000.00
										(twenty thousand Rand) in cash.
									</li>
									<li>
										For the purposes of The BacheLEAGUE voting competition, an “active LottoStar Player” is defined as
										someone who has made at least a single paid bet through their own account on www.lottostar.co.za in
										the last 7 (seven) days. For the avoidance of doubt, a free bet (Freebie, Promo credit and/or
										Voucher) does not constitute a bet for the purposes of being considered an active LottoStar player.
									</li>
									<li>
										A participant in The BacheLEAGUE may only win a cash prize once during The Bachelor South Africa
										Season 2.
									</li>
									<li>
										Provided all terms and conditions are met and all information as is required has been provided, cash
										prizes will be paid to winners within 7 (seven) working days of notice via Electronic Funds Transfer
										(EFT).
									</li>
									<li>
										Only one answer to the main question per participant per week is allowed, this entry is linked to an
										identity number (ID).
									</li>
									<li>
										Where there are a number of correct answers to the weekly main question, LottoStar will give all
										participants who answered correctly a free scratch/entry into The Bachelor ScratchCard Game on
										LottoStar, for their chance to win up to R6.5million. A participant is only entitled to one free
										entry per week into the The Bachelor ScratchCard Game on LottoStar.
									</li>
								</ol>
							</li>
							<li>
								The Bachelor ScratchCard Game
								<ol>
									<li>
										Participants will have to register an account with LottoStar before they can participate in The
										Bachelor ScratchCard Game and stand a chance to win up to R6.5million.
									</li>
									<li>
										Participants will only be able to bet on the game using the Freebie that they have been awarded – no
										Play Credits, Vouchers or Promotional Credits can be used.
									</li>
									<li>
										Any winnings from The Bachelor ScratchCard Game will be credited directly to a Player’s Play Credits
										account.
									</li>
									<li>
										The Bachelor ScratchCard Game results off of the Zamlottery 5 of 30 Plus event, a Player would need
										to match 5 of 30 from the first ball set and 3 of 15 bonus numbers from the second ball set in order
										to win the maximum payout of R6.5million. The full paytable can be viewed on The Bachelor
										ScratchCard Game.
									</li>
								</ol>
							</li>
						</ol>
						<strong>Toyota Terms and Conditions</strong>
						<ol>
							<li>
								The prize cannot be exchanged for any other model within the range and the winner does not have the
								right to select the colour and/or spec of the vehicle.
								<ol>
									<li>The prize does not include insurance on the vehicle.</li>
									<li>The prize does not include any extras such as: Fuel and Additional accessories</li>
									<li>The prize is not transferrable</li>
									<li>A valid South African Driver’s licence is required to claim the prize</li>
									<li>
										Upon verification of the winner by the Promoter (DSTV), the winner should select
										his Toyota Dealership of choice. The vehicle will be delivered to the dealership for licensing,
										registration, and hand-over. Pre-delivery costs will be carried by Toyota South Africa
									</li>
									<li>
										After the winner is announced and his/her Toyota dealership of choice is selected, a lead time of up
										to 8 weeks can apply for vehicle order, delivery and handover.
									</li>
								</ol>
							</li>
						</ol>
						<strong>M-Net Terms and Conditions</strong>
						<ol>
							<li>
								These are the standard terms and conditions for competitions conducted or promoted by or in association
								with the MultiChoice South Africa Group (“MultiChoice”).
							</li>
							<li>
								Your entry into the competition and/or your acceptance of a prize (in the event that you win a prize)
								constitutes your binding acceptance of the terms and conditions on behalf of yourself and any person
								with whom you may share a prize (in the event that you win a prize which is for you and one or more
								additional persons (“your partner”).
							</li>
							<li>
								The competition is not open to:
								<ol>
									<li>
										directors, members, partners, agents, employees, contestants, crew, participants or consultants of
										the Naspers Group of companies or any supplier of goods or services in connection with a
										competition; and
									</li>
									<li>
										the spouse, life partner, family member, business partner or associate, or the natural or adopted
										parent, child, or sibling, of any of the persons specified in 3.1 above.
									</li>
								</ol>
							</li>
							<li>
								Entrants under the age of 18 must obtain permission from their parents or guardians before entering.
							</li>
							<li>
								Unless otherwise specified in the competition rules, you may only enter the competition once any time
								before the closing date.
							</li>
							<li>
								You may not participate in or win a competition if you have won a competition promoted by us within the
								past three (3) months. You will be eligible to participate in a competition promoted by us after the
								expiry of three (3) months after the date on which you won the last competition.
							</li>
							<li>
								If you use a mobile phone for entry into the competition, the telephone calls / text messages you make
								will be charged at the prevailing rates, which may vary from time to time. "Free" minutes under a
								cell-phone contract do not apply.
							</li>
							<li>
								It is your responsibility to ensure that your entry is received by us prior to the closure of the
								competition. Any entries which are not received by us prior to the closure of the competition will not
								be eligible to participate, regardless of the reason for the late entry. We and our affiliates are not
								responsible for any entries which are not received by us, whether timeously or at all, regardless of the
								cause thereof. Without limitation, we and our affiliates are not responsible for any problems or
								technical malfunction of any telephone network or lines, computer on-line systems, servers, or
								providers, computer hardware or software failure or malfunction, traffic congestion (whether physical,
								or on the Internet, telephone lines or at any service provider, website or other device or medium), or
								any combination thereof, or any other technical or other problems.
							</li>
							<li>
								We and our affiliates are not responsible for any injury or damage to your or any other person’s
								computer, mobile telephone or other device used by you to enter into, or obtain any materials related to
								the competition.
							</li>
							<li>
								It is your responsibility to ensure that any information which you provide to us is accurate, complete
								and up to date.
							</li>
							<li>
								Any costs or expenses which you may incur other than in respect of those items specifically included in
								a prize are for your own account. MultiChoice will not be responsible for any costs or expenses which
								you, or your partner (if applicable), incur during and for purposes of your entry into the competition
								and your acceptance and/or use of a prize.
							</li>
							<li>
								We do not make any representations or give any warranties, whether expressly or implicitly, as to a
								prize, and in particular, but without limitation, make no representations and give no warranty that –
								<ol>
									<li>
										your entry or participation in the competition will necessarily result in you winning a prize;
									</li>
									<li>
										a prize, or any aspect thereof, will meet your, or, if applicable, your partner’s, requirements,
										preferences, standards or expectations; or
									</li>
									<li>
										a prize, or any aspect thereof, will be satisfactory, punctual, free from defects, safe or reliable.
									</li>
								</ol>
							</li>
							<li>
								We and our affiliates will not be responsible for any harm, damage, loss or claim relating to the
								provision of any element of a prize or any changes to a prize that may be made at any time.
							</li>
							<li>
								Prizes are not transferable and may not be deferred, changed or exchanged for cash or any other item.
							</li>
							<li>
								You may not win a prize if it is unlawful for us to supply such a prize to you. If you do win such a
								prize, you will forfeit it.
							</li>
							<li>
								You must possess whatever documents and permissions that may be required in order to accept and use a
								prize, including, a valid identity document or passport.
							</li>
							<li>
								The winners will be notified by means of the contact details provided to us. If we are, or a third party
								supplier is, unable to contact a winner within 10 working days or if the winner is unable to collect the
								prize within 60 days, the winner will forfeit the prize and MultiChoice reserves the right to re-draw a
								new winner under the same conditions.
							</li>
							<li>
								We may invite you to be present when the prize winners are determined or announced, to participate in
								any of our marketing activities, to appear in person in the electronic media and / or the print media,
								and / or to endorse, promote or advertise any of our goods or services, for which no fee, royalty or
								other compensation will be payable. You may decline such invitation.
							</li>
							<li>We may publish names of the participants and winners on any of our communication platforms.</li>
							<li>
								We may require you to provide us with such additional information and documentation as we may reasonably
								require in order to process, confirm and facilitate your acceptance and/or use of a prize. If you refuse
								to provide us with the requested information or documentation, you will forfeit the prize.
							</li>
							<li>
								In the event that you win a prize provided by a third party supplier, the supplier will contact you to
								arrange the collection thereof. In this regard, you must collect the prize at the time and date arranged
								with the supplier of the prize. All correspondence regarding the prize must be directed at the supplier
								of the prize. MultiChoice will not be responsible for any further correspondence, harm, damage, loss or
								claim relating to the provision of any element of the prize.
							</li>
							<li>
								We and our third party suppliers, as the case may be, reserve the right to vary, postpone, suspend, or
								cancel the competition and any prizes, or any aspect thereof, without notice at any time, for any reason
								which we deem necessary. In the event of such variation, postponement, suspension or cancellation, you
								agree to waive any rights, interests and expectations that you may have in terms of this competition and
								acknowledge that you will have no recourse against us, our affiliates and third party suppliers.
							</li>
							<li>
								You agree that your participation in the competition, and your acceptance and/or use of a prize, or any
								aspect thereof, is at your own risk.
							</li>
							<li>
								We and our affiliates will not be responsible, and disclaim all liability, for any loss, liability,
								injury, expense or damage (whether direct, indirect, incidental, punitive or consequential) of any
								nature, whether arising from negligence or any other cause, which is suffered by your participation in
								the competition or the acceptance and/or use by you, or your partner (if applicable), of any prize, or
								by any action taken by us or any of our affiliates in accordance with the terms and conditions.
							</li>
							<li>
								You, and in the event of your death, your family, dependants, heirs, assignees or any other
								beneficiaries of your estate, indemnify and hold us and our affiliates harmless against any claim by
								you, or your partner (if applicable), (whether direct, indirect, incidental, punitive or consequential)
								of any nature, whether arising from negligence or any other cause, relating to any injury, loss,
								liability, expense and/or damage which you may suffer, howsoever arising, in relation to your entry into
								this competition and / or acceptance and / or use by you of a prize.
							</li>
							<li>
								You acknowledge that the acceptance and use of a prize is subject to the proviso that –
								<ol>
									<li>
										all of the terms and conditions will apply to both you and your partner (if applicable), and you
										will ensure that your partner agrees to be bound and complies, and will continue to comply,
										therewith;
									</li>
									<li>you will take full responsibility for your partner;</li>
									<li>
										you indemnify and hold us and our affiliates harmless against any claim by your partner or any third
										party in the event that your partner suffers any loss or damage pursuant to your partner’s
										acceptance and/or use of a prize; and
									</li>
									<li>any minor will be accompanied by a parent or legal guardian.</li>
								</ol>
							</li>
							<li>
								If you fail or, if your partner (if applicable) fails, to comply with any of the terms and conditions,
								then without prejudice to any other remedy which we may have and to the extent permitted in law, –
								<ol>
									<li>
										you will be automatically disqualified and you will forfeit the prize/s (in the event that you have
										already won a prize);
									</li>
									<li>
										you will pay us for any loss or damage incurred by us directly or indirectly as a result of your
										(or, if applicable, your partner's) non-compliance, including all of our legal costs (including
										attorney and own client costs) which we may incur in taking any steps pursuant to your (or your
										partner’s) non-compliance; and
									</li>
									<li>
										you indemnify and hold us and our affiliates harmless against any claim by any person, (whether
										direct, indirect, incidental, punitive or consequential) of any nature, whether arising from
										negligence or any other cause, relating to any death, injury, loss and/or damage which may be
										suffered howsoever arising in relation to your failure (or that of your partner, if applicable) to
										comply therewith.
									</li>
								</ol>
							</li>
							<li>
								For purposes hereof, "affiliate" means our partners, co promoters and sponsors of this competition, our
								subsidiaries, our and their subsidiaries and respective holding companies, the subsidiaries of their
								holding companies, and our and their directors, officers, employees, agents and representatives.
							</li>
							<li>
								These terms and conditions will be construed, interpreted and enforced in accordance with the laws of
								contract and dispute resolution in the Republic of South Africa.
							</li>
							<li>
								MultiChoice and the judges' decision on any matter concerning the competition and/or arising out of
								these terms and conditions is final and binding on you, and no correspondence will be entered into.
							</li>
							<li>
								To the extent that there is any conflict between these terms and conditions and the Sponsor competition
								rules and the Sponsor terms and conditions, the order of precedence of such documents will be as
								follows-
								<ol>
									<li>Sponsor competition rules; then</li>
									<li>Multichoice terms and conditions; then</li>
									<li>Sponsor terms and conditions.</li>
								</ol>
							</li>
						</ol>
					</div>
				</div>
			</div>
		</Reveal>
	);
};

export default Terms;
