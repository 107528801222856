const sanityClient = require('@sanity/client');

const client = sanityClient({
	projectId: process.env.REACT_APP_SANITY_PROJECT_ID,
	dataset: process.env.REACT_APP_SANITY_DATASET,
	token: process.env.REACT_APP_SANITY_TOKEN,
	useCdn: true
});

export default client;
