import 'portal/Bachelor/static/index.scss';
import './Questions.scss';

import React, { useEffect, useState } from 'react';

import Button from 'portal/Bachelor/components/Button';
import Layout from '../components/Layout';
import { queryCMS } from 'global/queries';
import store from 'store';
import withConfig from 'global/components/HOC/withConfig';

const Questions = ({ pulse, history }) => {
	const { questionsAnswered, entryID } = pulse;
	let [questions, setQuestions] = useState(null);

	useEffect(() => {
		queryCMS(
			`*[_type == "portalQuestion" && featured != true && disabled != true && start_date <= $currentDate && end_date >= $currentDate && !(_id in path('drafts.**'))]`,
			{ currentDate: new Date().toISOString() }
		)
			.then(res => setQuestions(res))
			.catch(() => alert('Something went wrong, please try again'));
	}, []);

	return (
		<Layout>
			<p className="text-center">
				Just for a little bit of added entertainment, we’ve created a poll with some exciting questions! Vote on the
				below and see if you and your fellow viewers are in sync or if you’ll have one of those “you cannot be serious”
				shout out loud kind of moments!
			</p>
			{questions && (
				<>
					<ul className="bachelor--main-questions">
						{questions.map((question, index) => {
							let hasAnswered = questionsAnswered[entryID] && questionsAnswered[entryID].includes(question._id);
							return (
								<li key={index}>
									<div className="bachelor--main-questions-icon">{index + 1}</div>
									<h2>{question.question}</h2>
									<Button
										disabled={hasAnswered}
										outlined={!hasAnswered}
										onClick={() => history.push(`/bachelor/questions/${question.slug.current}`)}
										small
										text={hasAnswered ? 'VOTE SUBMITTED' : 'SUBMIT VOTE'}
									/>
								</li>
							);
						})}
					</ul>
					{/* eslint-disable-next-line */}
					<a target="_blank" href="https://lottostar.co.za/?aid=bacheleague_home">
						<Button centered text="TAKE ME TO LOTTOSTAR" />
					</a>
				</>
			)}
		</Layout>
	);
};

export default withConfig(
	store.wrapped(Questions, {
		entryID: 'auth/entryID',
		questionsAnswered: 'bachelor/questionsAnswered'
	}),
	{
		slug: 'bachelor'
	}
);
