export default {
	data: {
		questionsAnswered: {},
		loginOpen: false
	},
	actions: {
		setQuestionsAnswered: ({ data }, question, key) => {
			if (data.questionsAnswered[key]) {
				data.questionsAnswered = {
					...data.questionsAnswered,
					[key]: [...data.questionsAnswered[key], question]
				};
			} else {
				data.questionsAnswered = {
					...data.questionsAnswered,
					[key]: [question]
				};
			}
		},
		setLoginOpen: ({ data }, val) => {
			data.loginOpen = val;
		}
	},
	persist: ['questionsAnswered']
};
