import checkAPI from 'global/functions/checkAPI';
import fetch from 'isomorphic-unfetch';
import store from 'store';

const queryApiClient = ({ url, method, data, authorization, alert = true, signal, action }) => {
	const token = store.auth.clientToken;
	return new Promise((resolve, reject) => {
		fetch(`${process.env.REACT_APP_API_URL + url}`, {
			method: method || 'POST',
			signal,
			headers: {
				Authorization: authorization || `Bearer ${token}`,
				'Content-Type': 'application/json',
				'x-channel': 'lottostar_c'
			},
			body: data ? JSON.stringify(data) : null
		})
			.then(res => res.json())
			.then(res => checkAPI(res))
			.then(res => {
				if (res['data']) {
					if (typeof window !== 'undefined' && window.logs === true) {
						console.groupCollapsed(
							'%c%s',
							'color: #fff; background: #19406d; font-size: 12px;',
							' Client Api Response ',
							`${action ? `| ${action}` : ''}`
						);
						console.log(res);
						console.groupEnd();
					}
					store.auth.setLoginTokenExpiry(res._metadata.token.expire_at);
					resolve(res);
					return res;
				} else if (res['errors']) {
					// if (!store.auth.codeBlacklist.includes(res['errors'][0].code)) store.error.addError(res['errors']);
					reject(res['errors']);
					return res['errors'];
				}
			})
			.catch(err => {
				if (err.code !== 20) {
					const response = [
						{
							message: 'Something went wrong, please try again',
							error: err
						}
					];
					// store.error.addError(response);
					reject(response);
					return response;
				}
			});
	});
};

export default queryApiClient;
