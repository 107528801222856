import client from '../sanity';

const queryCMS = (query, params, toKeys) => {
	return new Promise((resolve, reject) => {
		client
			.fetch(query, params || '')
			.then(response => {
				if (toKeys) {
					let keyArray = {};
					// eslint-disable-next-line
					response.map(item => {
						keyArray[item.slug.current] = {
							...item
						};
					});
					resolve(keyArray);
					return keyArray;
				} else {
					resolve(response);
					return response;
				}
			})
			.catch(error => {
				const response = [
					{
						message: 'Something went wrong, please try again',
						error
					}
				];
				// store.error.addError(response);
				reject(response);
				return response;
			});
	});
};

export default queryCMS;
