import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import BlockContent from '@sanity/block-content-to-react';
import { FaTimes } from 'react-icons/fa';
import React from 'react';
import Reveal from 'react-reveal/Reveal';
import urlFor from 'global/functions/urlFor';

const ContestantModal = props => {
	const { contestants, contestant, setActive } = props;

	const handleClose = () => {
		setActive(null);
	};

	const handleContestantChange = action => {
		const currentIndex = contestants.indexOf(contestant);
		if (action === '+') {
			if (currentIndex + 1 === contestants.length) {
				setActive(contestants[0]);
			} else {
				setActive(contestants[currentIndex + 1]);
			}
		} else {
			if (currentIndex === 0) {
				setActive(contestants[contestants.length - 1]);
			} else {
				setActive(contestants[currentIndex - 1]);
			}
		}
	};

	return (
		<Reveal effect="fadeIn" duration={400}>
			<div className="bachelor--contestants-overlay" onClick={handleClose}>
				<div className="bachelor--contestants-modal" onClick={e => e.stopPropagation()}>
					<div className="bachelor--contestants-modal-close" onClick={handleClose}>
						<FaTimes />
					</div>
					<img src={urlFor(contestant.avatar)} alt={contestant.name} />
					<h2 className="mt10">
						{contestant.name}, {contestant.age}
						<br />
						<span style={{ fontSize: '16px' }}>{contestant.title}</span>
					</h2>
					{contestant.writeup && (
						<div className="bachelor--contestants-modal-content">
							<BlockContent blocks={contestant.writeup} />
						</div>
					)}
					<div className="bachelor--contestants-modal-arrow next" onClick={() => handleContestantChange('+')}>
						<FaArrowRight />
					</div>
					<div className="bachelor--contestants-modal-arrow prev" onClick={() => handleContestantChange('-')}>
						<FaArrowLeft />
					</div>
				</div>
			</div>
		</Reveal>
	);
};

export default ContestantModal;
