import client from 'global/sanity';

const createEntry = (question, selectedContestant) => {
	return new Promise((resolve, reject) => {
		client
			.patch(question._id)
			.setIfMissing({ entries: [] })
			.unset([`entries[_key == "entries-${selectedContestant.slug.current}"]`])
			.append('entries', [
				{
					_key: `entries-${selectedContestant.slug.current}`,
					value: 1,
					contestant: { _type: 'reference', _ref: selectedContestant._id }
				}
			])
			.inc({ totalEntries: 1 })
			.commit()
			.then(res => {
				resolve(res);
				return res;
			})
			.catch(err => {
				reject(err);
				return err;
			});
	});
};

const createStatistic = (question, selectedContestant) => {
	return new Promise((resolve, reject) => {
		let canPatch = false;
		if (question.entries && question.entries.length > 0) {
			// eslint-disable-next-line
			question.entries.map(entry => {
				if (entry._key === `entries-${selectedContestant.slug.current}`) {
					canPatch = true;
				}
			});
			if (canPatch) {
				const key = `entries[_key=="entries-${selectedContestant.slug.current}"].value`;
				client
					.patch(question._id)
					.inc({ [key]: 1, totalEntries: 1 })
					.commit()
					.then(res => {
						resolve(res);
						return res;
					})
					.catch(err => {
						reject(err);
						return err;
					});
			} else {
				createEntry(question, selectedContestant)
					.then(res => {
						resolve(res);
						return res;
					})
					.catch(err => {
						reject(err);
						return err;
					});
			}
		} else {
			createEntry(question, selectedContestant)
				.then(res => {
					resolve(res);
					return res;
				})
				.catch(err => {
					reject(err);
					return err;
				});
		}
	});
};

export default createStatistic;
