import React from 'react';

const Button = props => {
	const { text, className, onClick, type, centered, small, outlined, loading, disabled, style } = props;

	return (
		<>
			{centered ? (
				<div className={`bachelor--button-container centered ${className || ''}`}>
					<button
						disabled={disabled}
						onClick={onClick}
						type={type || 'button'}
						className={`bachelor--button-container-button ${disabled ? 'disabled' : ''} ${small ? 'small' : ''} ${
							outlined ? 'outlined' : ''
						} ${loading ? 'loading' : ''}`}
					>
						{text}
					</button>
				</div>
			) : (
				<button
					style={style}
					disabled={disabled}
					onClick={onClick}
					type={type || 'button'}
					className={`bachelor--button-container-button ${disabled ? 'disabled' : ''} ${small ? 'small' : ''} ${
						outlined ? 'outlined' : ''
					} ${loading ? 'loading' : ''} ${className || ''}`}
				>
					{text}
				</button>
			)}
		</>
	);
};

export default Button;
