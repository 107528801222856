import NoMatch from 'NoMatch/index';
import React from 'react';
import { queryCMS } from 'global/queries';

function withConfig(WrappedComponent, config) {
	return class extends React.Component {
		state = {
			portal: {},
			mounted: false
		};

		componentDidMount() {
			queryCMS(`*[_type == 'portal' && slug.current == $slug && !(_id in path('drafts.**'))] [0]`, {
				slug: config.slug
			})
				.then(res => {
					this.setState({ portal: res });
					setTimeout(() => {
						this.setState({ mounted: true });
					}, 50);
				})
				.catch(() => alert('Something went wrong, please try again'));
		}

		render() {
			if (
				!config.slug ||
				this.state.portal.start_date >= new Date().toISOString() ||
				this.state.portal.end_date <= new Date().toISOString()
			) {
				return <NoMatch />;
			}
			return (
				<main style={!this.state.mounted ? { opacity: 0 } : null} id={`portal__${config.slug}`}>
					<WrappedComponent {...this.props} portal={this.state.portal} />
				</main>
			);
		}
	};
}

export default withConfig;
