import './VotingClosed.scss';

import Button from 'portal/Bachelor/components/Button';
import Closed from 'portal/Bachelor/static/closed.png';
import React from 'react';

const VotingClosed = () => {
	return (
		<div className="bachelor-404">
			<img src={Closed} alt="Voting Closed" />
			<p className="mt40">
				<strong>Voting is currently closed.</strong>
				<br /> Check back soon for an opportunity to place your vote and stand a chance to win R20 000.
			</p>
			{/* eslint-disable-next-line */}
			<a target="_blank" href="https://lottostar.co.za/?aid=bacheleague_home">
				<Button text="take me to lottostar" />
			</a>
		</div>
	);
};

export default VotingClosed;
