import React, { useEffect, useState } from 'react';

import { Col } from 'react-bootstrap';
import { queryCMS } from 'global/queries';
import urlFor from 'global/functions/urlFor';

const PollResults = () => {
	let [questions, setQuestions] = useState(null);
	let [resultedQuestions, setResultedQuestions] = useState([]);
	let [resultedContestants, setResultedContestants] = useState([]);
	let [resultedValues, setResultedValues] = useState([]);
	let [hide, setHide] = useState(false);

	useEffect(() => {
		queryCMS(
			`*[_type == 'portalQuestion' && disabled != true && featured != true && start_date <= $currentDate && end_date >= $currentDate && !(_id in path('drafts.**'))] { ..., "entries": entries[] {..., "contestant": contestant->} }`,
			{
				currentDate: new Date().toISOString()
			}
		)
			.then(res => {
				if (res.length > 0) {
					setQuestions(res);
				} else {
					setHide(true);
				}
			})
			.catch(() => alert('Something went wrong, please try again'));
	}, []);

	useEffect(() => {
		if (questions) {
			let questionGroup = [];
			let contestantsGroup = [];
			let valuesGroup = [];

			questions.map(question => {
				let entryValue = 0;
				let contestant = null;

				if (question.entries) {
					question.entries.map(entry => {
						if (entryValue < entry.value) {
							entryValue = entry.value;

							contestant = entry.contestant;
						}
						return true;
					});
				}

				questionGroup = [question, ...questionGroup];
				if (contestant) {
					contestantsGroup = [contestant, ...contestantsGroup];
				}
				if (question.totalEntries > 0) {
					valuesGroup = [
						parseFloat((entryValue / question.totalEntries) * 100)
							.toFixed(1)
							.replace(/\.?0+$/, ''),
						...valuesGroup
					];
				}
				return true;
			});

			setResultedQuestions(questionGroup);
			setResultedContestants(contestantsGroup);
			setResultedValues(valuesGroup);
		}
	}, [questions]);

	return (
		<Col lg={12} xl={4} style={hide ? { display: 'none' } : null}>
			{questions && resultedValues.length > 0 && (
				<div className="bachelor--poll-results">
					<h1 className="text-center mb20">Poll Results</h1>

					{resultedContestants && (
						<>
							{resultedQuestions.map((question, index) => (
								<React.Fragment key={index}>
									{resultedContestants[index] && (
										<div className="bachelor--poll-results-result">
											<div className="bachelor--poll-results-result-image">
												<img src={urlFor(resultedContestants[index].avatar)} alt={resultedContestants[index].name} />
											</div>

											<div className="bachelor--poll-results-result-content">
												<div className="bachelor--poll-results-result-content-question">{question.question}</div>
												<div className="bachelor--poll-results-result-content-name">
													{resultedContestants[index].name}
												</div>
											</div>

											<div className="bachelor--poll-results-result-stats">
												<div className="bachelor--poll-results-result-stats-value">{resultedValues[index]}%</div>
											</div>
										</div>
									)}
								</React.Fragment>
							))}
						</>
					)}
				</div>
			)}
		</Col>
	);
};

export default PollResults;
