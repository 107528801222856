import * as Yup from 'yup';

import { Col, Row } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import Button from 'portal/Bachelor/components/Button';
import { queryApiClient } from 'global/queries';
import store from 'store';

const LoginSchema = Yup.object().shape({
	contact_number: Yup.string()
		.required('This field is required')
		.matches(/^(0)\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, {
			message: 'Please enter valid a contact number. E.g. 079\xa0XXX\xa0XXXX',
			excludeEmptyString: false
		}),
	login_pin: Yup.string()
		.required('This field is required')
		.matches(new RegExp('^[0-9]+$'), 'PIN can only contain numbers')
		.test('len', 'Please enter a 4 digit PIN', val => val && val.toString().length === 4)
});

const LoginForm = ({ pulse, setResend, active, resend }) => {
	const { setLoginToken, setLoginTokenExpiry, setEntryID } = pulse;
	let [error, setError] = useState(null);
	let [submitting, setSubmitting] = useState(false);

	const handleSubmit = values => {
		setSubmitting(true);
		setError(null);
		queryApiClient({
			url: '/portal/competitionentrylogin',
			method: 'POST',
			data: {
				portal_slug: 'the-bachelor',
				mobile: values.contact_number.replace(/ /g, ''),
				entry_pin: values.login_pin
			}
		})
			.then(res => {
				setLoginTokenExpiry(res._metadata.token.expire_at);
				setLoginToken(res.data.login_token);
				setEntryID(res.data.entry_id);
			})
			.catch(err => {
				setError(err[0].message);
				setSubmitting(false);
			});
	};

	return (
		<>
			<Formik
				validationSchema={LoginSchema}
				onSubmit={values => handleSubmit(values)}
				initialValues={{
					contact_number: '',
					login_pin: ''
				}}
			>
				{({ errors, touched }) => (
					<Form>
						<Row noGutters>
							<Col xs={12}>
								<Field name="contact_number" type="text" placeholder="Contact Number" />
								{errors.contact_number && touched.contact_number && (
									<div className="input-wrapper-error">{errors.contact_number}</div>
								)}
							</Col>
							<Col xs={12}>
								<Field name="login_pin" type="password" placeholder="PIN" />
								{errors.login_pin && touched.login_pin && <div className="input-wrapper-error">{errors.login_pin}</div>}
							</Col>
							<Col xs={12}>{error && <div className="error alt mb10 mt10">{error}</div>}</Col>
							<Col xs={5}>
								<Button loading={submitting} type="submit" text="LOGIN" />
							</Col>
							<Col>
								<div className="bachelor--login--container-resend">
									Click <strong onClick={() => setResend(true)}>here</strong> to resend PIN
								</div>
							</Col>
						</Row>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default store.wrapped(LoginForm, {
	setLoginToken: 'auth/setLoginToken',
	setLoginTokenExpiry: 'auth/setLoginTokenExpiry',
	setEntryID: 'auth/setEntryID'
});
