import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'global/static/base.scss';

import * as Sentry from '@sentry/browser';

import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import cssVars from 'css-vars-ponyfill';

cssVars({
	onlyLegacy: true
});

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_KEY });

ReactDOM.render(<App />, document.getElementById('root'));
