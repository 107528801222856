import { Col, Container, Row } from 'react-bootstrap';
import React, { useEffect } from 'react';

import BannerImage from 'portal/Bachelor/static/banner.jpg';
import Contestants from './Contestants';
import Error from 'portal/Bachelor/components/Error';
import Helmet from 'react-helmet';
import PollResults from './PollResults';
import moment from 'moment';
import store from 'store';
import { withRouter } from 'react-router-dom';

const Layout = props => {
	const { history, blank } = props;
	const { setLoginToken, loginTokenExpiry, setClientToken } = props.pulse;

	useEffect(() => {
		const now = new Date();
		const expiry = new Date(moment(loginTokenExpiry).format());
		if (now >= expiry) {
			setLoginToken(false);
			setClientToken(false);
		}
	}, [loginTokenExpiry, setLoginToken, setClientToken]);

	return (
		<Container fluid className="bachelor p0">
			<Helmet>
				<title>The Bachelor | LottoStar</title>
			</Helmet>
			<Row>
				<Col xs={12}>
					{/* eslint-disable-next-line */}
					<a target="_blank" href="https://lottostar.co.za/?aid=bacheleague_home">
						<img
							style={{ verticalAlign: 'middle', position: 'relative', zIndex: '1' }}
							src={BannerImage}
							alt="LottoStar"
						/>
					</a>
				</Col>
				<Col xs={12}>
					<Contestants />
				</Col>
			</Row>
			<Row>
				<Col xs={12}>
					{blank === true ? (
						<div className="bachelor--main">{props.children}</div>
					) : (
						<div className={`bachelor--main ${history.location.pathname !== '/bachelor' ? 'internal' : ''}`}>
							{history.location.pathname !== '/bachelor' && history.location.pathname !== '/bachelor/' && (
								<div
									onClick={() => {
										setLoginToken(false);
										store.auth.setEntryID(null);
									}}
									className="bachelor--main-logout"
								>
									LOGOUT
								</div>
							)}
							<Error />
							<Row>
								<Col className="bachelor--main-content">{props.children}</Col>
								<PollResults />
							</Row>
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default store.wrapped(withRouter(Layout), {
	loginTokenExpiry: 'auth/loginTokenExpiry',
	setLoginToken: 'auth/setLoginToken',
	setClientToken: 'auth/setClientToken'
});
