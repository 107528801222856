import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import AuthRoute from 'global/components/AuthRoute';
import FeaturedQuestion from 'portal/Bachelor/pages/FeaturedQuestion';
import { Helmet } from 'react-helmet';
import Home from 'portal/Bachelor/pages/Home';
import NoMatch from 'NoMatch/index';
import PollQuestion from 'portal/Bachelor/pages/PollQuestion';
import Questions from 'portal/Bachelor/pages/Questions';
import { queryApiClient } from 'global/queries';
import store from 'store';

const App = props => {
	const { loginToken, clientToken, setClientToken } = props.pulse;

	useEffect(() => {
		if (!clientToken) {
			queryApiClient({
				url: '/login',
				authorization: `Basic ${btoa(`${process.env.REACT_APP_CLIENT_NAME}:${process.env.REACT_APP_CLIENT_SECRET}`)}`
			})
				.then(response => setClientToken(response.data.access_token))
				.catch(() => alert('Something went wrong, please try again'));
		}
	}, [clientToken, setClientToken]);

	return (
		<>
			<Helmet>
				<script>{`setInterval(function() {window.top.postMessage(document.body.scrollHeight, '*')}, 1000);`}</script>
			</Helmet>
			<Router>
				<Switch>
					<AuthRoute
						auth={!loginToken}
						redirect="/bachelor/questions/featured"
						exact
						path="/bachelor"
						component={Home}
					/>
					<AuthRoute auth={loginToken} redirect="/bachelor" exact path="/bachelor/questions" component={Questions} />
					<AuthRoute
						auth={loginToken}
						redirect="/bachelor"
						exact
						path="/bachelor/questions/featured"
						component={FeaturedQuestion}
					/>
					<AuthRoute
						auth={loginToken}
						redirect="/bachelor"
						exact
						path="/bachelor/questions/:slug"
						component={PollQuestion}
					/>
					<Route>
						<NoMatch />
					</Route>
				</Switch>
			</Router>
		</>
	);
};

export default store.wrapped(App, {
	clientToken: 'auth/clientToken',
	loginToken: 'auth/loginToken',
	setClientToken: 'auth/setClientToken'
});
