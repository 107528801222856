import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';

import ContestantModal from './ContestantModal';
import Slider from 'react-slick';
import { queryCMS } from 'global/queries';
import urlFor from 'global/functions/urlFor';

const Contestants = () => {
	let [contestants, setContestants] = useState(null);
	let [activePerson, setActivePerson] = useState(null);
	let [isMobi, setIsMobi] = useState(window.innerWidth < 1200 ? true : false);

	useEffect(() => {
		queryCMS(
			`*[_type == "portalContestant" && slug.current != 'no-one' && slug.current != 'marc' && !(_id in path('drafts.**'))] | order(order asc)`
		)
			.then(res => setContestants(res))
			.catch(() => alert('Something went wrong, please try again'));
	}, []);

	useEffect(() => {
		window.addEventListener('resize', () => {
			window.innerWidth < 1200 ? setIsMobi(true) : setIsMobi(false);
		});
	}, []);

	const NextArrow = props => (
		<div className="bachelor--contestants-arrow next" onClick={props.onClick}>
			<FaArrowRight />
		</div>
	);
	const PrevArrow = props => (
		<div className="bachelor--contestants-arrow prev" onClick={props.onClick}>
			<FaArrowLeft />
		</div>
	);

	const settings = {
		dots: false,
		arrows: true,
		autoplay: true,
		pauseOnHover: true,
		focusOnSelect: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 7,
					slidesToScroll: 2,
					infinite: true
				}
			},
			{
				breakpoint: 991,
				settings: {
					centerMode: true,
					slidesToShow: 4,
					slidesToScroll: 2,
					infinite: true
				}
			},
			{
				breakpoint: 640,
				settings: {
					centerMode: false,
					slidesToShow: 4,
					slidesToScroll: 4,
					infinite: true
				}
			}
		]
	};

	return (
		<div className="bachelor--contestants">
			{activePerson && (
				<ContestantModal contestants={contestants} setActive={setActivePerson} contestant={activePerson} />
			)}
			{contestants && (
				<>
					<h1 className="text-center text-uppercase mb30">
						FIND OUT MORE ABOUT THE {contestants.length} WOMEN WHO ARE FIGHTING FOR MARC’S HEART!
					</h1>
					{isMobi ? (
						<Slider {...settings}>
							{contestants.map((person, index) => {
								return (
									<div
										onClick={() => setTimeout(() => setActivePerson(person), settings.speed)}
										key={index}
										className="bachelor--contestants-contestant"
									>
										<img src={urlFor(person.avatar)} alt={person.name} />
									</div>
								);
							})}
						</Slider>
					) : (
						<>
							{contestants.map((person, index) => {
								let style = {
									animationDelay: index * 0.06 + 's'
								};
								return (
									<div
										style={style}
										onClick={() => setActivePerson(person)}
										key={index}
										className="bachelor--contestants-contestant active"
									>
										<img src={urlFor(person.avatar)} alt={person.name} />
									</div>
								);
							})}
						</>
					)}
				</>
			)}
		</div>
	);
};

export default Contestants;
