import { Col, Row } from 'react-bootstrap';

import React from 'react';
import urlFor from 'global/functions/urlFor';

const ContestantSelection = props => {
	const { contestants, selectedContestant, setSelectedContestant, featured } = props;

	const handleSelect = contestant => {
		if (selectedContestant && selectedContestant.slug.current === contestant.slug.current) {
			setSelectedContestant(null);
		} else {
			setSelectedContestant(contestant);
		}
	};

	return (
		<div className="bachelor--selection-container">
			<div className="bachelor--selection">
				<Row noGutters className="justify-center">
					{contestants.map((contestant, index) => {
						if (featured === false && contestant.slug.current === 'no-one') {
							return null;
						} else if (featured === true && contestant.slug.current === 'marc') {
							return null;
						} else {
							return (
								<Col xl={3} lg={4} md={4} sm={6} key={index}>
									<div
										className={`bachelor--selection-contestants ${contestant.disabled ? 'disabled' : ''} ${
											selectedContestant && selectedContestant.slug.current === contestant.slug.current ? 'active' : ''
										}`}
										onClick={() => handleSelect(contestant)}
									>
										<div className="bachelor--selection-contestants-image">
											<img src={urlFor(contestant.portrait)} alt={contestant.name} />
										</div>
										<div className="bachelor--selection-contestants-content">
											<div className="bachelor--selection-contestants-content-name">{contestant.name}</div>
											<div className="bachelor--selection-contestants-content-title">{contestant.title}</div>
										</div>
										<div className="bachelor--selection-contestants-container"></div>
									</div>
								</Col>
							);
						}
					})}
				</Row>
			</div>
			<div className="bachelor--selection-fade" />
		</div>
	);
};

export default ContestantSelection;
