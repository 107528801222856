import 'portal/Bachelor/static/index.scss';

import React, { useEffect, useState } from 'react';
import { queryApiClient, queryCMS } from 'global/queries';

import Button from '../components/Button';
import ContestantSelection from '../components/ContestantSelection';
import Layout from '../components/Layout';
import QuestionModal from 'portal/Bachelor/components/QuestionModal';
import VotingClosed from 'portal/Bachelor/components/VotingClosed';
import createStatistic from 'portal/Bachelor/functions/createStatistic';
import store from 'store';
import withConfig from 'global/components/HOC/withConfig';

const FeaturedQuestion = ({ pulse, history }) => {
	const { loginToken } = pulse;
	let [contestants, setContestants] = useState(null);
	let [selectedContestant, setSelectedContestant] = useState(null);
	let [hasEnded, setHasEnded] = useState(false);
	let [submitting, setSubmitting] = useState(false);
	let [error, setError] = useState(null);
	let [question, setQuestion] = useState(null);
	let [votingClosed, setVotingClosed] = useState(false);

	useEffect(() => {
		queryCMS(`*[_type == "portalContestant" && !(_id in path('drafts.**'))] | order(order asc)`)
			.then(res => setContestants(res))
			.catch(() => setVotingClosed(true));

		queryCMS(
			`*[_type == "portalQuestion" && featured == true && disabled != true && start_date <= $currentDate && end_date >= $currentDate && !(_id in path('drafts.**'))][0]`,
			{
				currentDate: new Date().toISOString()
			}
		)
			.then(res => {
				if (!res) {
					setVotingClosed(true);
				} else {
					setQuestion(res);
				}
			})

			.catch(() => {
				setVotingClosed(true);
			});
	}, [history]);

	useEffect(() => {
		queryApiClient({
			url: `/portal/competitionsegmententry?portal_slug=the-bachelor&login_token=${loginToken}`,
			method: 'GET'
		})
			.then(res => {
				if (res.data.status === 'completed' && question && !hasEnded) {
					history.push('/bachelor/questions');
				}
			})
			.catch(() => setVotingClosed(true));
	}, [history, question, hasEnded, loginToken]);

	const handleSubmit = _id => {
		setSubmitting(true);
		setError(null);
		queryApiClient({
			url: '/portal/competitionsegmententry',
			method: 'POST',
			data: {
				portal_slug: 'the-bachelor',
				login_token: loginToken,
				selection_id: selectedContestant.entry_id
			}
		})
			.then(response => {
				createStatistic(question, selectedContestant)
					.then(res => {
						setHasEnded(true);
					})
					.catch(() => {
						setSubmitting(false);
					});
			})
			.catch(err => {
				setError(err[0].message);
				setSubmitting(false);
			});
	};
	return (
		<>
			<Layout>
				{votingClosed ? (
					<VotingClosed />
				) : (
					<>
						{contestants && question && (
							<>
								{hasEnded && <QuestionModal questionID={question._id} />}
								<p className="text-center">
									While Marc finds the love of his life, your chance to WIN is right here, on the BacheLEAGUE! The
									correct prediction to the question below and a little bit of luck will see you win R5,000 in cash BUT
									wait, if you’ve placed a bet on lottostar.co.za in the last 7 days we’ll make that an incredible
									R20,000! Vote now:
								</p>
								<h2 className="text-center mb30 mt20">{question.question}</h2>

								<ContestantSelection
									featured
									contestants={contestants}
									selectedContestant={selectedContestant}
									setSelectedContestant={setSelectedContestant}
								/>

								<center className="bachelor--selection-buttons">
									{error && <div className="error mb10 mt10">{error}</div>}
									<Button
										loading={submitting}
										disabled={!selectedContestant}
										onClick={() => handleSubmit(question._id)}
										text={'Submit Vote'}
									/>
								</center>
							</>
						)}
					</>
				)}
			</Layout>
		</>
	);
};

export default withConfig(
	store.wrapped(FeaturedQuestion, {
		loginToken: 'auth/loginToken'
	}),
	{
		slug: 'bachelor'
	}
);
