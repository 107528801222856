import * as Yup from 'yup';

import { Col, Row } from 'react-bootstrap';
import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';

import Button from 'portal/Bachelor/components/Button';
import { queryApiClient } from 'global/queries';

const ResendSchema = Yup.object().shape({
	contact_number: Yup.string()
		.required('This field is required')
		.matches(/^(0)\(?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, {
			message: 'Please enter valid a contact number. E.g. 079\xa0XXX\xa0XXXX',
			excludeEmptyString: false
		})
});

const ResendForm = ({ setResend, active, resend }) => {
	let [resendSuccess, setResendSuccess] = useState(false);
	let [error, setError] = useState(null);
	let [submitting, setSubmitting] = useState(false);

	const handleSubmit = values => {
		setSubmitting(true);
		setError(null);
		queryApiClient({
			url: '/portal/competitionentryforgotpin',
			method: 'PUT',
			data: {
				portal_slug: 'the-bachelor',
				mobile: values.contact_number.replace(/ /g, '')
			}
		})
			.then(() => setResendSuccess(true))
			.catch(err => {
				setError(err[0].message);
				setSubmitting(false);
			});
	};

	return (
		<>
			<Formik
				validationSchema={ResendSchema}
				onSubmit={values => handleSubmit(values)}
				initialValues={{
					contact_number: ''
				}}
			>
				{({ errors, touched }) => (
					<Form>
						{resendSuccess ? (
							<Row noGutters>
								<Col xs={12}>Your PIN has been re-sent to your mobile number.</Col>
								<Col>
									<div
										onClick={() => {
											setResend(false);
											setResendSuccess(false);
										}}
										style={{ lineHeight: '14px', marginTop: '10px' }}
										className="bachelor--login--container-resend"
									>
										<strong>Take me back to Login</strong>
									</div>
								</Col>
							</Row>
						) : (
							<Row noGutters>
								<Col xs={12}>
									<Field name="contact_number" type="text" placeholder="Contact Number" />
									{errors.contact_number && touched.contact_number && (
										<div className="input-wrapper-error">{errors.contact_number}</div>
									)}
								</Col>
								<Col xs={12}>{error && <div className="error alt mb10 mt10">{error}</div>}</Col>
								<Col xs={5}>
									<Button loading={submitting} type="submit" text="SUBMIT" />
								</Col>
								<Col>
									<div
										onClick={() => {
											setResend(false);
											setResendSuccess(false);
										}}
										className="bachelor--login--container-resend"
									>
										<strong>Take me back to Login</strong>
									</div>
								</Col>
							</Row>
						)}
					</Form>
				)}
			</Formik>
		</>
	);
};

export default ResendForm;
