import store from 'store';

// Function that logs a user out if auth token is expired or invalid
const checkAPI = response => {
	if (response.errors) {
		response.errors.map(error => {
			if (error.code === 5020 || error.code === 3062) {
				store.auth.setClientToken(false);
				store.auth.setLoginToken(false);
				window.location.reload();
			}
			return true;
		});
	}
	return response;
};

export default checkAPI;
