import './login.scss';

import React, { useEffect, useState } from 'react';

import { FaTimes } from 'react-icons/fa';
import LoginForm from './LoginForm';
import ResendForm from './ResendForm';
import store from 'store';

const Login = props => {
	const { setLoginOpen } = props.pulse;
	let [active, setActive] = useState(false);
	let [resend, setResend] = useState(false);

	useEffect(() => {
		setLoginOpen(active);
	}, [active, setLoginOpen]);

	return (
		<>
			<div className={`bachelor--login ${active ? 'active' : ''} `} onClick={() => setActive(!active)}>
				LOGIN
			</div>
			<div className={`bachelor--login--container ${active ? 'active' : ''} `}>
				<div className="bachelor--login--container--exit" onClick={() => setActive(false)}>
					<FaTimes />
				</div>
				{resend ? (
					<ResendForm setResend={setResend} />
				) : (
					<LoginForm resend={resend} active={active} setResend={setResend} />
				)}
			</div>
		</>
	);
};

export default store.wrapped(Login, {
	setLoginOpen: 'bachelor/setLoginOpen'
});
