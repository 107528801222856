import Pulse from 'pulse-framework';
import React from 'react';
import auth from './auth';
import bachelor from './bachelor';
import error from './error';

export default new Pulse({
	config: {
		framework: React
	},
	collections: {
		auth,
		error,
		bachelor
	}
});
