import 'portal/Bachelor/static/index.scss';

import React, { useEffect, useState } from 'react';

import Button from '../components/Button';
import ContestantSelection from '../components/ContestantSelection';
import Layout from '../components/Layout';
import QuestionModal from 'portal/Bachelor/components/QuestionModal';
import createStatistic from 'portal/Bachelor/functions/createStatistic';
import { queryCMS } from 'global/queries';
import store from 'store';
import { useParams } from 'react-router-dom';
import withConfig from 'global/components/HOC/withConfig';

const PollQuestion = ({ pulse, history }) => {
	const { questionsAnswered, setQuestionsAnswered, entryID } = pulse;
	let { slug } = useParams();
	let [hasEnded, setHasEnded] = useState(false);
	let [contestants, setContestants] = useState(null);
	let [selectedContestant, setSelectedContestant] = useState(null);
	let [submitting, setSubmitting] = useState(false);
	let [error, setError] = useState(null);
	let [question, setQuestion] = useState(null);

	useEffect(() => {
		queryCMS(`*[_type == "portalContestant" && !(_id in path('drafts.**'))] | order(order asc)`)
			.then(res => setContestants(res))
			.catch(() => alert('Something went wrong, please try again'));
		queryCMS(
			`*[_type == "portalQuestion" && slug.current == $slug && disabled != true && featured != true && start_date <= $currentDate && end_date >= $currentDate && !(_id in path('drafts.**'))] [0]`,
			{
				slug,
				currentDate: new Date().toISOString()
			}
		)
			.then(res => {
				if (!res) {
					history.push('/bachelor/questions');
				} else {
					setQuestion(res);
				}
			})
			.catch(() => alert('Something went wrong, please try again'));
	}, [slug, history]);

	useEffect(() => {
		if (question && questionsAnswered[entryID] && questionsAnswered[entryID].includes(question._id) && !hasEnded) {
			history.push('/bachelor/questions');
		}
	}, [question, questionsAnswered, hasEnded, history, entryID]);

	const markQuestionComplete = _id => {
		setQuestionsAnswered(_id, entryID);
	};

	const handleSubmit = _id => {
		setSubmitting(true);
		setError(null);
		createStatistic(question, selectedContestant)
			.then(res => {
				markQuestionComplete(_id);
				setHasEnded(true);
			})
			.catch(err => {
				setError(err.message);
				setSubmitting(false);
			});
	};

	return (
		<>
			<Layout>
				{contestants && question && (
					<>
						{hasEnded && <QuestionModal questionID={question._id} />}
						<h2 className="text-center mb30 mt20">{question.question}</h2>

						<ContestantSelection
							featured={false}
							contestants={contestants}
							selectedContestant={selectedContestant}
							setSelectedContestant={setSelectedContestant}
						/>

						<center className="bachelor--selection-buttons">
							{error && <div className="error mb10 mt10">{error}</div>}
							<Button
								style={{ margin: '5px', zIndex: 4, position: 'relative' }}
								onClick={() => history.push('/bachelor/questions')}
								text={'BACK TO POLL'}
							/>

							<Button
								style={{ margin: '5px', zIndex: 4, position: 'relative' }}
								loading={submitting}
								disabled={!selectedContestant}
								onClick={() => handleSubmit(question._id)}
								text={'Submit Vote'}
							/>
						</center>
					</>
				)}
			</Layout>
		</>
	);
};

export default withConfig(
	store.wrapped(PollQuestion, {
		entryID: 'auth/entryID',
		questionsAnswered: 'bachelor/questionsAnswered',
		setQuestionsAnswered: 'bachelor/setQuestionsAnswered'
	}),
	{
		slug: 'bachelor'
	}
);
